import { useStaticQuery, graphql } from 'gatsby';
import { AgendaNode } from '../components/events-list';

export const querySharePointEvents = (): ({ node: AgendaNode }[]) => {
  const { allOrganisatieVerenigingskalenderListItem } = useStaticQuery(
    graphql`
    {
      allOrganisatieVerenigingskalenderListItem(filter: {data: {fields: {Zichtbaarinagenda: {eq: true}, Status: {eq: "Definitief"}}}}) {
        edges {
          node {
            data {
              fields {
                Selectieveld
                Onderdeel
                Soort
                Title
                Beschrijving
                Status
                Zichtbaarinagenda
                Datum
                _EndDate
                Locatie: Locatie1
                social: Verwijzingsocialmedia {
                  Url
                }
                Adres
                Plaats
                Land_x002f_regio
                Modified
                sdate: Datum(formatString: "DD MMMM YYYY", locale: "nl-NL")
                sdayofmonth: Datum(formatString: "DD", locale: "nl-NL")
                sday: Datum(formatString: "dddd", locale: "nl-NL")
                smonth: Datum(formatString: "MMMM", locale: "nl-NL")
                smonth_short: Datum(formatString: "MMM", locale: "nl-NL")
                syear: Datum(formatString: "YYYY", locale: "nl-NL")
                stime: Datum(formatString: "HH:mm", locale: "nl-NL")
                edate: _EndDate(formatString: "DD MMMM YYYY", locale: "nl-NL")
                edayofmonth: _EndDate(formatString: "DD", locale: "nl-NL")
                emonth: _EndDate(formatString: "MMMM", locale: "nl-NL")
                etime: _EndDate(formatString: "HH:mm", locale: "nl-NL")
              }
              id
            }
          }
        }
        totalCount
      }
    }
    `
  )
  return allOrganisatieVerenigingskalenderListItem.edges
}