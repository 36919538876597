import { Link } from 'gatsby';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faClock, faLocationArrow, faMapPin } from '@fortawesome/free-solid-svg-icons'
import styles from './events-list.module.scss';
import { querySharePointEvents } from '../queries/sharepoint';
import moment from 'moment';


export interface AgendaNode {
  data: {
    fields: {
      id: string
      Soort: string,
      Onderdeel: string,
      Title: string,
      Beschrijving: string,
      Datum: string,
      _EndDate: Date,
      Locatie: string,
      Adres: string,
      Plaats: string,
      Land_x002f_regio: string;
      social: { Url: string}
      sdayofmonth: string,
      sdate: string,
      syear: string
      smonth_short: string,
      smonth: string,
      sday: string,
      stime: string,
      edate: string,
      edayofmonth: string,
      emonth: string,
      etime: string,
    }
  }
}

interface EventListProps {
  limit?: number,
  eventTypes?: string[]
}

const EventsList: React.SFC<EventListProps> = ({
  limit,
  eventTypes = ['Optreden', 'Activiteit'],
}) => {
  const listItems: ({ node: AgendaNode }[]) = querySharePointEvents().filter(x => eventTypes.indexOf(x.node.data.fields.Soort) > -1);
  const agendaItems = listItems.map(x => x.node.data);

  const startOfToday = moment().startOf('day').toDate();

  const upcomingEvents = agendaItems
    .filter(x => x.fields._EndDate && new Date(x.fields._EndDate).getTime() >= startOfToday.getTime())
    .sort((x, y) => new Date(x.fields.Datum).getTime() - new Date(y.fields.Datum).getTime());
  const upcomingEventsList = upcomingEvents.filter((_, index) => !limit || index < limit);
  const upcomingEventsHeaderText = upcomingEventsList.length > 0 ? 'Aankomende' : 'Geen aankomende';

  return (
    <div>
      <h4 className="text-center text-muted">
          <span>{upcomingEventsHeaderText}&nbsp;<Link to={`/events`} activeClassName="font-weight-bold">evenementen</Link> | <Link to={`/rehearsals`} activeClassName="font-weight-bold">repetities</Link>.</span>
      </h4>
      <p className='mb-5 text-center text-muted'>
        <small>Deze informatie is onderhevig aan wijzigingen. Tijden dienen enkel ter indicatie.</small>
      </p>
      {upcomingEventsList.map(data => {
        const unit = getVerenigingsonderdeelLabel(data.fields.Onderdeel);
        const startTime = data.fields.Datum ? moment(data.fields.Datum).format('HH:mm') : '';
        const endTime = data.fields._EndDate ? moment(data.fields._EndDate).format('HH:mm') : '';
        const endTimeDateSuffix = data.fields.edate && data.fields.edate !== data.fields.sdate && endTime !== '00:00' ? `tot ${data.fields.edayofmonth} ${data.fields.emonth}` : '-';
        const ignorableCountryValues = ['Nederland', null];
        const landSuffix = ignorableCountryValues.indexOf(data.fields.Land_x002f_regio) > -1 ? '' : `, ${data.fields.Land_x002f_regio}`;
        
        return (
          <div key={`event-${data.fields.id}`} className={`row ${styles.rowStriped} ${styles.rowHover} d-flex flex-nowrap deco-none`}>
            <div className={`px-3 px-lg-4 px-xl-5 text-center`}>
              <h2 className="display-4"><span className={`badge badge-secondary ${styles.dateBadge}`}>{data.fields.sdayofmonth}</span></h2>
              <h2>{data.fields.smonth_short.replace('.','').toUpperCase()}</h2>
              <h3 className="text-primary">{data.fields.syear}</h3>
            </div>
            <div className="flex-grow-1 px-3 text-left">
              <h3 className="mb-0 text-uppercase"><strong>{data.fields.Title}</strong></h3>
              <h5 className="my-0 text-uppercase text-muted font-weight-light">{unit}</h5>
              <ul className="list-inline mt-3">
                <li className="list-inline-item">
                  <FontAwesomeIcon icon={faCalendar} className="mr-2" />
                  <span>{`${data.fields.sday.substring(0,1).toUpperCase()}${data.fields.sday.substring(1)}`} {data.fields.sdayofmonth} {data.fields.smonth}</span>
                </li>
                <li className="list-inline-item">
                  <FontAwesomeIcon icon={faClock} className="mr-2" />
                  {startTime === '00:00' ? '' : <span>{startTime}</span>}
                  {!data.fields.etime || data.fields.etime === '00:00' ? '' : <span> {endTimeDateSuffix} {endTime}</span>}
                </li>
                <li className="list-inline-item">
                  <FontAwesomeIcon icon={faLocationArrow} className="mr-2" />
                  <span>{data.fields.Plaats}{landSuffix}</span>
                </li>
                {data.fields.Locatie ? (
                  <li className="list-inline-item">
                    <FontAwesomeIcon icon={faMapPin} className="mr-2" />
                    <a className='deco-none' target='_blank' href={`https://www.google.nl/maps/search/${data.fields.Locatie}%20${data.fields.Adres || ''}%20${data.fields.Plaats || ''}%20${data.fields.Land_x002f_regio || ''}`}>{data.fields.Locatie}</a>
                  </li>
                ) : (null) }
              </ul>
              <p className='text-muted'>
                { data.fields.Beschrijving?.length > 165
                  ? `${data.fields.Beschrijving.substring(0, 150 + data.fields.Beschrijving.substring(150).indexOf(' '))}...`
                  : data.fields.Beschrijving
                }
                &nbsp;
                { data.fields.social ? (
                  <a href={data.fields.social.Url} target="_blank" className="text-muted font-italic">
                    Bekijk het evenement op social media.
                  </a> ) : (<span></span>)
                }
              </p>
            </div>
          </div>
        )
      })}
      {limit && upcomingEventsList.length < upcomingEvents.length ?
        (
          <p className="mt-5 mb-0">
            <Link to="/events">
              Meer gebeurtenissen
            </Link>
          </p>
        ) : null
      }
    </div>
  );

  function getVerenigingsonderdeelLabel(unit: string): string {
    switch (unit) {
      case null: return 'Verenigingsbreed'; break;
      case 'Jubal': return 'Jubal drum & bugle corps'; break;
      case 'Jong Jubal': return 'Jong Jubal drum & bugle corps'; break;
      default: return unit;
    }
  }
}

export default EventsList;